
import "./contact.scss";

export default function Contact() {
  
  const formUrl = 'https://script.google.com/macros/s/AKfycbxNHuWuRwkXE6DhHQ9jwZ_OLfj_Lph22BpjbSJ5sBB3TckW_IRJhRVsNjcbzh1OtZBJ/exec'
  
  return (
    <div className="contact" id="contact">
      <div className="left">
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        <h2>Contact.</h2>
        <form  name="frm"  
                  method="post"
                  action={formUrl}>
          <input type="text" name="Name" class="form-control" placeholder="Full Name" required />
          <input type="text" name="Tel" class="form-control" placeholder="Phone Number" required />      
          <input type="email" name="Email" placeholder="Email" required  />
          <textarea name="Text"  placeholder="Message" required ></textarea>
          <button type="submit"  onclick="">Send</button>
          
        </form>
      </div>
    </div>
  );
}
