export const featuredPortfolio = [
    {
      id: 1,
      title: "OTP Platform(NETFLIX)",
      img:
        "https://cdn.dribbble.com/users/852957/screenshots/9780094/media/b4171594216da7e2b7c338e96a6d9762.png",
    },
    {
      id: 2,
      title: "Landing Page",
      img:
        "https://mahir.eu.org/q/21.jpg",
    },
    {
      id: 3,
      title: "E-commerce Web Design",
      img:
        "https://cdn.dribbble.com/users/1387827/screenshots/15466426/media/deb2dca6762cd3610321c98bfccb0b72.png?compress=1&resize=1200x900",
    },
    {
      id: 4,
      title: "React Mobile App",
      img:
        "https://cdn.dribbble.com/users/4095861/screenshots/15467417/media/d6a15c416626f12b31fa5ca1db192572.png?compress=1&resize=1200x900",
    },
    {
      id: 5,
      title: "Web Design",
      img:
        "https://cdn.dribbble.com/users/5031392/screenshots/15467520/media/c36b3b15b25b1e190d081abdbbf947cf.png?compress=1&resize=1200x900",
    },
    {
      id: 6,
      title: "Music App",
      img:
        "https://cdn.dribbble.com/users/5779106/screenshots/14725758/media/193790659beb9288c75ab9af6dd81319.png?compress=1&resize=1200x900",
    },
  ];
  
  export const webPortfolio = [
    {
      id: 1,
      title: "OTP Platform(NETFLIX)",
      img:
        "https://cdn.dribbble.com/users/852957/screenshots/9780094/media/b4171594216da7e2b7c338e96a6d9762.png",
    },
    {
      id: 2,
      title: "Landing Page",
      img:
        "https://mahir.eu.org/q/21.jpg",
    },
    {
      id: 3,
      title: "Web E-commerce Design",
      img: "https://cdn.dribbble.com/users/3637068/screenshots/15467010/media/69a3279182b75365013fe285733d1122.png?compress=1&resize=1200x900",
    },
    {
      id: 4,
      title: "Web React App",
      img: "https://cdn.dribbble.com/users/2264844/screenshots/15463998/media/d85e92a332c41094f68e1f716884f7ce.jpg?compress=1&resize=1200x900",
    },
    {
      id: 5,
      title: "Web Design",
      img: "https://cdn.dribbble.com/users/387658/screenshots/15458608/media/e92ec1dd33dd1170ae3dc9c5272f2693.jpg?compress=1&resize=1200x900",
    },
    {
      id: 6,
      title: "Web Design App",
      img: "https://cdn.dribbble.com/users/6384483/screenshots/15468426/media/ce9479fa1f8dba3a4a49840d76e55e31.png?compress=1&resize=1200x900",
    },
  ];
  
  export const mobilePortfolio = [
    {
      id: 1,
      title: "Mobile Calculator",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/c.png",
    },
    {
      id: 2,
      title: "Mobile React UI Design",
      img:"https://cdn.dribbble.com/users/2597268/screenshots/15468893/media/44313553d73ba41580f1df69749bba28.jpg?compress=1&resize=1200x900",
    },
    {
      id: 3,
      title: "Mobile E-commerce Design",
      img:
        "https://cdn.dribbble.com/users/1998175/screenshots/15459384/media/48ac2b43ebe81ba0866afea1383cc939.png?compress=1&resize=1200x900",
    },
    {
      id: 4,
      title: "Mobile React App",
      img:
        "https://cdn.dribbble.com/users/2083704/screenshots/15468619/media/cd958306c7a772449e1ac23bd65ce506.png?compress=1&resize=1200x900",
    },
    {
      id: 5,
      title: "Mobile App Design",
      img:
        "https://cdn.dribbble.com/users/26642/screenshots/15462545/media/1a202ef5c8338b6f78634e6edc1ba350.png?compress=1&resize=1200x900",
    },
    {
      id: 6,
      title: "Mobile Music App",
      img:
        "https://cdn.dribbble.com/users/5779106/screenshots/14725758/media/193790659beb9288c75ab9af6dd81319.png?compress=1&resize=1200x900",
    },
  ];
  
  export const designPortfolio = [
    {
      id: 1,
      title: "Design Social Media App",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    {
      id: 2,
      title: "Design UI Design",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    {
      id: 3,
      title: "Design E-commerce Web Design",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    {
      id: 4,
      title: "Design React Mobile App",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    
  ];
  
  export const contentPortfolio = [
    {
      id: 1,
      title: "Content Social Media App",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    {
      id: 2,
      title: "Content React UI Design",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    {
      id: 3,
      title: "Content E-commerce Web Design",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    {
      id: 4,
      title: "Content React Mobile App",
      img:
        "https://github.com/M1QL1/sit-9999999ffb11123455sefbrsndth/raw/main/q/dd.gif",
    },
    
    {
      id: 6,
      title: "Content Music App",
      img:
        "https://cdn.dribbble.com/users/5779106/screenshots/14725758/media/193790659beb9288c75ab9af6dd81319.png?compress=1&resize=1200x900",
    },
  ];
  