import "./topbar.scss";
import { Person, Mail } from "@material-ui/icons";


export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          
          <div onClick={()=>window.open("#intro", "_self")}>
            {/* if(?) menu Open  show image 1 */} 
           {menuOpen ? (<img className="logo" src="/assets/logo2.svg" ></img>): <img className="logo" src="/assets/logo.svg" ></img> } 
           {/* else(:) menu Open then show image 2 */}
          </div>
             
           
          
          <div className="itemContainer">
            <Person className="icon" />
            <a href="tel:+880 1733556734" className="under" >
            <span>+880 1733556734</span>
            </a>
            
          </div>
          <div className="itemContainer">
            <Mail className="icon" />
            <a href="mailto:work@mahir.eu.org" className="under">
            <span>work@mahir.eu.org</span>
            </a>
            
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
