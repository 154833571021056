import "./menu.scss";


export default function Menu({ menuOpen, setMenuOpen }) {
  return (
    <div className={"menu "+(menuOpen && "active")}>
      <ul>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#intro">Home</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#works">Works</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="button-container">
      <a href="https://www.facebook.com/mahir.tanjim.3/" target="_blank">
      <button>Facebook</button>
      </a>
      <br />
      <br />
      <a href="https://www.instagram.com/mahirtanjim/" target="_blank">
      <button >Instagram</button>
      </a>
      </div>
    </div>
  );
}
