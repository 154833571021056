import "./intro.scss";
import { init } from "ityped";
import { useEffect, useRef } from "react";




export default function Intro() {
  
  const textRef = useRef();
  //Adding array of image file
  document.addEventListener("DOMContentLoaded", () => {
    const log = console.log,
    //"images added
      array = ["/emo/4.gif", "/emo/8.gif", "/emo/9.gif", "/emo/10.gif","/emo/11.gif","/emo/13.gif","/emo/7.gif","/emo/5.gif","/emo/3.gif","/emo/6.gif"],
      random = Math.floor(Math.random() * array.length),
      target = document.getElementById("target");
    target.src = `${array[random]}`;
    log(target);
  });

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed:60,
      strings: ["Student", "Designer", "Developer", "Curious", "Learner", "Creative"],
    });
  }, []);

  

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">

          <img id="target" src="./emo/4.gif" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi There, you are Awsome, this is</h2>
          <h1>Mahir Tanjim</h1>
          <h3>
             <span ref={textRef}></span>
          </h3>
        </div>
        <a href="#portfolio">
          <img src="assets/down.png" alt="" />
        </a>
      </div>
    </div>
  );
}
